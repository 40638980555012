<template>
  <div class="container mt-2">
    <b-row>
      <b-col md="3" />
      <b-col md="6">
        <div class="backg">
          <h4 style="margin-top: 5px">
            <router-link :to="{ name: 'home' }">
              <i class="fas fa-chevron-left fa-lg" />
              <span style="color: #000"> แจ้งถอนเงิน</span>
            </router-link>
          </h4>
        </div>
        <div class="depo">
          <div class="notice__list alert">
            <p style="margin-bottom: 5px;text-decoration: underline;">
              <strong>ขั้นตอนการถอนเครดิต</strong>
            </p>
            <p style="margin-top: -5px;">
              1). โปรดระบุจำนวนเงินที่ต้องการถอนเครดิต โดยมีขั้นต่ำที่ <strong>100</strong> บาท
            </p>
            <p style="margin-top: -5px;">
              2). กด <strong>"ยืนยันการถอน"</strong> ระบบจะทำการถอนเงินอัตโนมัติ
            </p>
          </div>

          <div
            class="alert"
            style="font-size: 0.85rem;margin-bottom: 10px;background-color: #898686;"
          >
            <p style="margin-bottom: 5px;">
              <span style="font-weight: bold;text-decoration: underline;">เตือน!</span>
              <span style="margin-left: 5px;">ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น</span>
            </p>
          </div>

          <div
            class="card"
            :style="`background-color: ${type === 'truewallet' ? '#ffbb81' : UserRes.bg};`"
          >
            <div class="logo">
              <img
                :src="`/bankIcon/${type === 'truewallet' ? 'wallet.png' :UserRes.path_photo}`"
                alt="logo-bank"
                height="75"
                style="float: right"
                class="mt-2"
              >
            </div>
            <div class="chip">
              <img
                src="/images/chip.png"
                alt="chip"
              >
              <span class="bankk">{{ type === 'truewallet' ? 'truewallet' : UserRes.bankname }}</span>
            </div>
            <div class="number">
              {{ type === 'truewallet' ? UserRes.trueaccno : UserRes.acc_no }}
            </div>
            <div class="name">
              {{ UserRes.fname }} {{ UserRes.sname }}
            </div>
            <!-- <div class="from">
              06/23
            </div> -->
            <div class="to">
              <i class="fas fa-coins" />&nbsp;{{ Commas(this.$store.state.appConfig.UserData.MainWallet) }} ฿
            </div>
            <div class="ring" />
          </div>

          <div class="withdraw mt-2">
            <label class="font-medium-1">จำนวนเงินที่ต้องการถอน</label>
            <b-input-group
              class="input-group-merge"
              size="lg"
            >
              <b-input-group-prepend is-text>
                <i class="fas fa-donate" />
              </b-input-group-prepend>
              <b-form-input
                v-model="amount"
                placeholder="โปรดระบุจำนวนเงิน"
              />
            </b-input-group>

            <b-button
              variant="primary"
              block
              class="mt-2"
              @click="SummitBet()"
            >
              <i class="fas fa-donate" /> ยืนยันการถอน
            </b-button>
          </div>
          <p class="mt-2 text-center text-danger">
            * โปรดทำรายการก่อนเวลา 23.30 - 01.00 น. เนื่องจากระบบธนาคารปิดปรับปรุง
          </p>
        </div>
      </b-col>
      <b-col md="3" />
    </b-row>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormInput,
  },
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      message: '',
      type: 'bank',
      UserRes: {
        username: '',
        tel: '',
        fname: '',
        sname: '',
        bank: '',
        acc_no: '',
        trueaccno: '',
        created_at: '',
      },
      amount: null,
      // IntervalWallet: null,
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.IntervalWallet)
  // },
  mounted() {
    this.ShowUser()
    // this.IntervalWallet = setInterval(() => {
    //   this.ShowWallet()
    // }, 5000)
  },
  methods: {
    async SummitBet() {
      if (this.amount && this.amount > 0) {
        const Obj = {
          amount: this.amount,
        }
        try {
          // const { data: response } = await this.$http.post(
          //   'https://api.ma5lotto.com/api/withdraw/store',
          //   Obj,
          // )
          const { data: response } = await this.$http.post(
            'https://api.ma5lotto.com/api/withdraw/store',
            Obj,
          )
          if (response && response.success === true) {
            this.ShowUser()
            const { data: res } = await this.$http.get('https://api.ma5lotto.com/api/wallet/show')
            if (res && res.success === true) {
              const Main = res.WalletData.MainWallet
              this.$store.state.appConfig.UserData.MainWallet = Main.balance
              this.SwalMes(
                'success',
                'แจ้งถอนสำเร็จ',
                'ระบบกำลังทำรายการกรุณารอสักครู่',
              )
              // ถอนทั้งหมด ${this.Commas(
              //     response.Data.amount,
              //   )} บาท ยอดคงเหลือ ${this.Commas(response.Data.balance)} บาท
            } else {
              this.$store.state.appConfig.UserData.MainWallet = 0
            }
          }
        } catch (err) {
          this.SwalMes('error', 'ถอนไม่สำเร็จ', err.response.data.Mes)
        }
      } else {
        this.SwalMes('error', 'ถอนไม่สำเร็จ', 'กรุราระบุจำนวนเงินที่ต้องการถอน')
      }
    },
    async ShowUser() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/user/show',
        )
        if (response && response.success === true) {
          this.type = response.type
          this.UserRes = response.UserData
        }
      } catch (err) {
        console.log(err)
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    SwalMes(Type, Title, Text) {
      this.$swal({
        icon: Type,
        title: Title,
        text: Text,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.alert {
    border-radius: 4px;
    border: none;
    color: rgb(0, 0, 0) !important;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.backg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  /* margin-left: 5px;
  margin-right: 5px; */
  border-radius: 15px;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.raduis {
  margin: auto;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
  border-radius: 5px;
}
.withdraw {
  max-width: 640px;
  margin: auto;
}
.depo {
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.img-bank {
  margin: auto;
  background: rgb(71, 1, 104);
  padding: 5px;
  height: 160px;
  width: 160px;
  border-radius: 50%;
}
/* .notice__list {
  font-size: 18px;
} */
.notice__list {
  font-size: 0.85rem;
  margin: auto;
  max-width: 640px;
  background: linear-gradient(47deg, #accb32, #bdd65a);
  /* border: 1px solid rgb(255, 0, 0); */
  /* padding: 15px 24px; */
  /* border-radius: 10px; */
  color: rgb(0, 0, 0);
  margin-bottom: 0.75rem;
}
.notice__list p.alert {
  display: flex;
}
.btn-copy-2 {
  color: #000000;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.balance-cl {
  margin: auto;
  background-color: #00a950;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
}
.balance-bay {
  background-color: #ffc323;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.font-res {
  font-size: 38px;
  color: #fff
}
@media screen and (max-width: 600px) {
  .font-res {
    font-size: 2rem;
  }
}
.circle {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
}
.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-1 {
  height: 180px;
  width: 180px;
  top: -50px;
  left: -60px;
}
.circle-2 {
  height: 200px;
  width: 200px;
  bottom: -90px;
  right: -90px;
  opacity: 0.8;
}
/* Background circles end */

/* .card-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.card {
  margin: auto;
  position: relative;
  height: 210px;
  max-width: 380px;
  border-radius: 15px;
  /* background: rgb(163, 0, 0); */
  backdrop-filter: blur(30px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.logo img,
.chip img,
.number,
.bankk,
.name,
.from,
.to,
.ring {
  position: absolute; /* All items inside card should have absolute position */
}

.logo img {
  top: 15px;
  right: 10px;
  width: 80px;
  height: auto;
  opacity: 0.8;
}

.chip img {
  top: 60px;
  left: 20px;
  width: 50px;
  height: auto;
  opacity: 0.8;
}
.bankk {
  top: 20px;
  left: 20px;
  font-size: 1.3rem;
  color: #fff;
}

.number,
.name,
.from,
.to {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.number {
  left: 20px;
  font-size: 1.5rem;
  bottom: 65px;
  font-family: 'Nunito', sans-serif;
}

.name {
  font-size: 1rem;
  left: 20px;
  bottom: 35px;
}

.from {
  font-size: 1rem;
  bottom: 35px;
  right: 90px;
}

.to {
  font-size: 1rem;
  bottom: 35px;
  right: 20px;
}

/* The two rings on the card background */
.ring {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  background: transparent;
  border: 50px solid rgba(255, 255, 255, 0.1);
  bottom: -250px;
  right: -250px;
  box-sizing: border-box;
}

.ring::after {
  content: '';
  position: absolute;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: transparent;
  border: 30px solid rgba(255, 255, 255, 0.1);
  bottom: -80px;
  right: -110px;
  box-sizing: border-box;
}

</style>
